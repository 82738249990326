import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <h1>404</h1>
    <p>Tražena stranica ne postoji.</p>
  </>
);

export default NotFoundPage;
